import http from '@/libs/http'
import { useStore } from '@/store'
import axios from 'axios'
const url = process.env.VUE_APP_MICROSERVICE_API_CONTRATO_PRECIO

export default class ListaPrecioService {
  descargarPlantillaListaPrecio () {
    const send = `${url}/templates/listaPrecio`
    const store = useStore()
    const params = { token: `Bearer ${store.getters['auth/getToken']}` }
    const res = http.getUri({ url: send, params })
    window.open(res, 'blank')
  }

  obtenerListaPorContrato (params, id) {
    return http.get(`${url}/contratos/${id}/listaPrecio`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginar (params) {
    return http.get(`${url}/listaPrecio/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginarMdsPreciosPorLista (params, idListaPrecio) {
    return http.get(`${url}/listaPrecio/paginate/${idListaPrecio}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  searchLista (name) {
    return http.get(`${url}/listaPrecio/search/${name}`, {
      header: {
        loading: true
      }
    })
  }

  crearListaPrecio (payload) {
    return http.post(`${url}/listaPrecio`, payload, {
      headers: {
        loading: true
      }
    })
  }

  editarListaPrecio (payload) {
    return http.put(`${url}/listaPrecio/rename`, payload, {
      headers: {
        loading: true
      }
    })
  }

  agregarMedicamentoAListaPrecio (payload) {
    return http.post(`${url}/listaPrecio/add`, payload, {
      headers: {
        loading: true
      }
    })
  }

  descargarListaPrecio (idLista) {
    const baseUrl = `${url}/listaPrecio/export/${idLista}`
    const store = useStore()
    const token = store.getters['auth/getToken']
    const res = axios.getUri({ url: baseUrl, params: { token: `Bearer ${token}` } })
    window.open(res, '_blank')
  }

  actualizarMedicamentosMasivo (id, payload) {
    return http.put(`${url}/listaPrecio/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
